/* Navrouter.css */

.navbar-custom {
  background-color: #007bff; /* Bootstrap primary color */
}

.navbar-custom .navbar-brand,
.navbar-custom .nav-link {
  color: white; /* White text for brand and links */
}

.navbar-custom .nav-link {
  margin: 0 10px; /* Spacing between links */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.navbar-custom .nav-link:hover {
  color: #ffcc00; /* Change color on hover */
}

.navbar-custom .navbar-toggler {
  border-color: white; /* White border for toggler */
}

.navbar-custom .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* AddProduct.css */
.form-container {
  width: 400px;
  margin: 0 auto;
  margin-top: 5vw;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-container form div {
  margin-bottom: 15px;
}

.form-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-container input[type="text"],
.form-container input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-container button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #45a049;
}

.message {
  text-align: center;
  font-size: 14px;
  color: red;
  margin-top: 10px;
}
/* AddProduct.css */
.view-products-button {
  margin-top: 20px;
  padding: 10px;
  background-color: #008CBA;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.view-products-button:hover {
  background-color: #007bb5;
}
/* ViewProducts.css */

.product-list {
  padding: 20px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
}

.product-card {
  border: 1px solid #ddd; /* Card border */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Inner padding */
  width: 20vw; /* Fixed width for each card */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center items */
}

.product-image {
  max-width: 100%; /* Responsive image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px; /* Rounded corners for image */
  margin-bottom: 10px; /* Space below image */
}

.edit-product-form {
  display: flex;
  flex-direction: column; /* Stack inputs vertically */
  align-items: center; /* Center items */
  width: 100%; /* Full width */
}

.edit-input {
  margin: 5px 0; /* Space between inputs */
  padding: 8px; /* Inner padding */
  border: 1px solid #ccc; /* Border */
  border-radius: 4px; /* Rounded corners */
  width: 90%; /* Full width of card */
}

.save-button,
.cancel-button,
.edit-button,
.delete-button {
  margin-top: 10px; /* Space above buttons */
  padding: 5px 10px; /* Button padding */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
}

.save-button {
  background-color: #28a745; /* Green color */
  color: white; /* White text */
}

.save-button:hover {
  background-color: #218838; /* Darker shade on hover */
}

.cancel-button {
  background-color: #ffc107; /* Warning color */
  color: white; /* White text */
}

.cancel-button:hover {
  background-color: #e0a800; /* Darker shade on hover */
}

.edit-button {
  background-color: #007bff; /* Bootstrap primary color */
  color: white; /* White text */
  width: 100%;
}

.edit-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.delete-button {
  background-color: #dc3545; /* Bootstrap danger color */
  color: white; /* White text */
  width: 100%;
}

.delete-button:hover {
  background-color: #c82333; /* Darker shade on hover */
}
